import {months} from '../../utils/mockup'

export default {
    components: {},
    data() {
        return {
            data: {
                current_page: 1,
                per_page: 10,
                total: 10
            },
            months: [],

            total_registrations_monthly_custom: {
                data: {},
                total: 0
            },
            registrations_monthly_custom: {
                data: {},
                total: 0
            },
            total_registrations_monthly_free: {
                data: {},
                total: 0
            },
            registrations_monthly_free: {
                data: {},
                total: 0
            },
            total_registrations_daily_custom: {
                data: {},
                total: 0
            },
            registrations_daily_custom: {
                data: {},
                total: 0
            },
            total_registrations_daily_free: {
                data: {},
                total: 0
            },
            registrations_daily_free: {
                data: {},
                total: 0
            },
            total_accounts_monthly_custom: {
                data: {},
                total: 0
            },
            accounts_monthly_custom: {
                data: {},
                total: 0
            },
            total_accounts_monthly_free: {
                data: {},
                total: 0
            },
            accounts_monthly_free: {
                data: {},
                total: 0
            },
            total_accounts_daily_custom: {
                data: {},
                total: 0
            },
            accounts_daily_custom: {
                data: {},
                total: 0
            },
            total_accounts_daily_free: {
                data: {},
                total: 0
            },
            accounts_daily_free: {
                data: {},
                total: 0
            },
            total_registrations_monthly: {
                data: {},
                total: 0
            },
            registrations_monthly: {
                data: {},
                total: 0
            },
            total_accounts_monthly: {
                data: {},
                total: 0
            },
            accounts_monthly: {
                data: {},
                total: 0
            },
            total_accounts_daily: {
                data: {},
                total: 0
            },
            accounts_daily: {
                data: {},
                total: 0
            },
            total_registrations_daily: {
                data: {},
                total: 0
            },
            registrations_daily: {
                data: {},
                total: 0
            },
            years: [],
            days: [],
            filter: {
                year: new Date().getFullYear(),
                month: ('0' + (new Date().getUTCMonth() + 1)).slice(-2),
                current_month: ('0' + (new Date().getUTCMonth() + 1)).slice(-2),
                current_year: new Date().getFullYear(),
                current_date: ('0' + (new Date().getDate())).slice(-2)
            }
        }
    },
    async mounted() {


        this.months = months
        await this.monthly()
        await this.populate_years()
        await this.populate_dates(this.filter.current_year, this.filter.current_month)

    },
    methods: {
        async daily() {


            await this.list_registration({
                date_from: this.filter.year + '-' + this.filter.month + '-01',
                date_to: this.filter.year + '-' + this.filter.month + '-31',
                status: 'all',
                mode: 'd',
                all: 1
            }, 'total_registrations_daily')

            await this.list_registration({
                date_from: this.filter.year + '-' + this.filter.month + '-01',
                date_to: this.filter.year + '-' + this.filter.month + '-31',
                status: 'all',
                mode: 'd',
                all: 0
            }, 'registrations_daily')

            await this.list_registration({
                date_from: this.filter.year + '-' + this.filter.month + '-01',
                date_to: this.filter.year + '-' + this.filter.month + '-31',
                status: 'free',
                mode: 'd',
                all: 1
            }, 'total_registrations_daily_free')

            await this.list_registration({
                date_from: this.filter.year + '-' + this.filter.month + '-01',
                date_to: this.filter.year + '-' + this.filter.month + '-31',
                status: 'free',
                mode: 'd',
                all: 0
            }, 'registrations_daily_free')


            await this.list_registration({
                date_from: this.filter.year + '-' + this.filter.month + '-01',
                date_to: this.filter.year + '-' + this.filter.month + '-31',
                status: 'custom',
                mode: 'd',
                all: 1
            }, 'total_registrations_daily_custom')

            await this.list_registration({
                date_from: this.filter.year + '-' + this.filter.month + '-01',
                date_to: this.filter.year + '-' + this.filter.month + '-31',
                status: 'custom',
                mode: 'd',
                all: 0
            }, 'registrations_daily_custom')

            await this.list_account_daily('registrations_daily', 'accounts_daily')
            await this.list_account_daily('total_registrations_daily', 'total_accounts_daily')
            await this.list_account_daily('registrations_daily_free', 'accounts_daily_free')
            await this.list_account_daily('total_registrations_daily_free', 'total_accounts_daily_free')
            await this.list_account_daily('registrations_daily_custom', 'accounts_daily_custom')
            await this.list_account_daily('total_registrations_daily_custom', 'total_accounts_daily_custom')

        },
        async monthly() {

            await this.list_registration({
                date_from: this.filter.year + '-01-01',
                date_to: this.filter.year + '-12-31',
                status: 'all',
                mode: 'm',
                all: 1
            }, 'total_registrations_monthly')

            await this.list_registration({
                date_from: this.filter.year + '-01-01',
                date_to: this.filter.year + '-12-31',
                status: 'all',
                mode: 'm',
                all: 0
            }, 'registrations_monthly')

            await this.list_registration({
                date_from: this.filter.year + '-01-01',
                date_to: this.filter.year + '-12-31',
                status: 'free',
                mode: 'm',
                all: 1
            }, 'total_registrations_monthly_free')

            await this.list_registration({
                date_from: this.filter.year + '-01-01',
                date_to: this.filter.year + '-12-31',
                status: 'free',
                mode: 'm',
                all: 0
            }, 'registrations_monthly_free')

            await this.list_registration({
                date_from: this.filter.year + '-01-01',
                date_to: this.filter.year + '-12-31',
                status: 'custom',
                mode: 'm',
                all: 1
            }, 'total_registrations_monthly_custom')

            await this.list_registration({
                date_from: this.filter.year + '-01-01',
                date_to: this.filter.year + '-12-31',
                status: 'custom',
                mode: 'm',
                all: 0
            }, 'registrations_monthly_custom')

            await this.list_account_monthly('registrations_monthly', 'accounts_monthly')
            await this.list_account_monthly('total_registrations_monthly', 'total_accounts_monthly')
            await this.list_account_monthly('registrations_monthly_free', 'accounts_monthly_free')
            await this.list_account_monthly('total_registrations_monthly_free', 'total_accounts_monthly_free')
            await this.list_account_monthly('total_registrations_monthly_custom', 'total_accounts_monthly_custom')
            await this.list_account_monthly('registrations_monthly_custom', 'accounts_monthly_custom')
        },
        async $select_year() {

            await this.monthly();
        },
        async $select_month() {
            await this.populate_dates(this.filter.year, this.filter.month)
            await this.daily();

        },

        async list_registration(data, object) {

            const response = await this.$lionheart.get('/dashboard/traction/registrations', {
                params: data
            })

            this[object] = response.data


        },

        async list_account_monthly(back, front) {

            var data = {};
            this.months.forEach((value, key) => {
                if (key == 0) {
                    data[value.number_name] = {total: this[back].data[value.number_name] ? this[back].data[value.number_name].total : 0}
                }
                if (key > 0) {
                    var total = 0;
                    var prev_total = 0;
                    if (this.filter.year < this.filter.current_year) {
                        total = this[back].data[value.number_name] ? this[back].data[value.number_name].total : 0;
                        prev_total = data[this.months[key - 1].number_name] ? data[this.months[key - 1].number_name].total : 0
                    } else if (value.number_name <= this.filter.current_month) {
                        total = this[back].data[value.number_name] ? this[back].data[value.number_name].total : 0;
                        prev_total = data[this.months[key - 1].number_name] ? data[this.months[key - 1].number_name].total : 0
                    }
                    data[value.number_name] = {total: total + prev_total}
                }
            })
            this[front].data = data
        },


        async list_account_daily(back, front) {
            var data = {};
            this.days.forEach((value, key) => {
                if (key == 0) {
                    data[value] = {total: this[back].data[value] ? this[back].data[value].total : 0}
                }
                if (key > 0) {
                    var total = 0;
                    var prev_total = 0;
                    if (this.filter.month < this.filter.current_month) {
                        total = this[back].data[value] ? this[back].data[value].total : 0
                        prev_total = data[this.days[key - 1]] ? data[this.days[key - 1]].total : 0
                    } else if (value <= this.filter.current_date) {

                        total = this[back].data[value] ? this[back].data[value].total : 0
                        prev_total = data[this.days[key - 1]] ? data[this.days[key - 1]].total : 0
                    }

                    data[value] = {total: total + prev_total}
                }
            });


            this[front].data = data;

        },
        async populate_years() {
            var date = new Date();
            for (var i = 2019; i <= date.getFullYear(); i++) {
                this.years.push(i);
            }
        },
        async populate_dates(year, month) {
            this.days = [];
            month = parseInt(month) - 1;
            var date = new Date(year, month, 1);
            while (date.getMonth() == month) {
                this.days.push(('0' + (new Date(date).getDate())).slice(-2));
                date.setDate(date.getDate() + 1);
            }
        },
    }
}
